import clsx from 'clsx';
import Structure from 'components/3cat/Structure';
import __isEmpty from 'lodash/isEmpty';
import styles from './columna.module.scss';

export default function Columna(props) {
    const { columnes, tipus, estil, className } = props;
    const columnClass = 'col' + columnes;

    const styleColumn = clsx({
        [styles[columnClass]]: true,
        [styles[tipus]]: !__isEmpty(tipus),
        [styles[estil]]: !__isEmpty(estil),
        [styles[className]]: !__isEmpty(className)
    });

    return (
        <div className={styleColumn}>
            <Structure {...props} />
        </div>
    );
}
